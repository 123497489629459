<template>
  <v-card class="pa-4 mt-4" max-width="600">
    <v-card-title class="px-1">Recuperación de contraseña</v-card-title>
    <v-form @submit.prevent="submitHandler">
      <v-text-field
        type="password"
        v-model="recover.password"
        filled
        label="Introduce tu nueva contraseña"
        :rules="[rules.req, rules.min, rules.containsNum]"
      >
      </v-text-field>
      <v-text-field
        type="password"
        v-model="recover.password2"
        filled
        label="Repite tu nueva contraseña"
        :rules="[
          rules.req,
          recover.password === recover.password2 ||
            'Las contraseñas no coinciden',
        ]"
      >
      </v-text-field>
      <v-btn large type="submit" color="primary" block>Confirmar</v-btn>
    </v-form>
  </v-card>
</template>

<script>
import { req, min, containsNum } from "@/utils/validationRules.js";
import axios from "redaxios";

export default {
  data() {
    return {
      rules: {
        req: req,
        min: (v) => min(v, 8),
        containsNum: containsNum,
      },
      recover: {
        password: "",
        password2: "",
      },
      codigo: this.$route.query.codigo,
    };
  },
  mounted() {
    if (this.codigo == null || this.codigo == undefined || this.codigo == "") {
      this.$router.push({ name: "Home" });
      this.$root.$emit("snack", "Parece que la dirección no es correcta");
    }

    let formData = new FormData();
    formData.append("accion", "comprobar");
    formData.append("codigo", this.codigo);
    axios({
      url: `${process.env.VUE_APP_API_URL}/comprobarclave.php`,
      method: "POST",
      data: formData,
    }).catch((e) => {
      console.error(e);
      this.$router.push({ name: "Home" });
    });
  },
  methods: {
    submitHandler() {
      let formData = new FormData();
      formData.append("accion", "actualizar");
      formData.append("codigo", this.codigo);
      formData.append("clave", this.recover.password);

      axios({
        url: `${process.env.VUE_APP_API_URL}/actualizarclave.php`,
        method: "POST",
        data: formData,
      })
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch((e) => console.error(e))
    },
  },
};
</script>